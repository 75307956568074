import { randomInteger } from './numbers';

const generateLotteriesBalls = lotteries => {
  return lotteries.map(function (lottery) {
    const balls = [];
    for (let i = 1; i <= lottery.ballsCount; i++) {
      let ball;
      do {
        ball = randomInteger(1, lottery.maxValue);
      } while (balls.includes(ball));
      balls.push(ball);
    }
    let bonusBall = null;
    if (lottery.bonusBall) {
      do {
        bonusBall = randomInteger(1, lottery.bonusBall);
      } while (balls.includes(bonusBall));
    }

    return {
      name: lottery.name,
      balls,
      bonusBall,
      img: lottery.img
    }
  });
};

export default generateLotteriesBalls;
