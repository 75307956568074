import React from 'react';

const Preloader = ({ overlay = false }) => (
  overlay ?
    <div className="preloader__overlay">
      <div className="preloader" />
    </div>
    :
    <div className="preloader" />
);

export default Preloader;
