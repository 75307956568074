import React from 'react';

const FormPopupText = ({ clickHandler }) => (
  <div className="contact-form__popup-text-wrapper">
    <a href="#" className='contact-form__popup-link' onClick={clickHandler}>
      Contact us for advertising and backlinks
    </a>
  </div>
);

export default FormPopupText;
