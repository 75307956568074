import React, { useState, useEffect } from 'react';
import { Link, navigate } from 'gatsby';
import Header from "../../../components/Header";
import CountryResultButtonsWrapper from '../../../components/Country/CountryResultButtonsWrapper';
import Footer from "../../../components/Footer";
import VoucherForm1 from "../../../components/VoucherForm/Variant1";
import VoucherForm2 from "../../../components/VoucherForm/Variant2";
import LotteriesResultsCountriesView from '../../components/LotteriesResultsCountriesView';
import CopyToClipboardButton from '../../../components/CopyToClipboardButton';
import useVoucherHandler from '../../../hooks/useVoucherHandler';
import { formatBalance } from '../../../helpers/voucher';
import { countries } from '../../../helpers/countries';
import { getGeneratedUrlForCountryResults } from '../../../helpers/utils';

const SINGLE_LOTTO_PRICE = Number.parseInt(process.env.REACT_COUNTRY_LOTTO_RESULTS);
const ALL_LOTTO_PRICE = Number.parseInt(process.env.REACT_ALL_COUNTRY_LOTTO_RESULTS);
const initialCountries = Object.entries(countries);

const ResultSelectPageTemplate = ({
  isMenuEnabled = false,
  voucherFormIsSubmitOutside = true,
  voucherLabel,
  backBtnCustomClass = '',
  isResultsCountryBtnOutside = false,
  isResultsHeaderOutside = false,
  voucherFormVariant = 1
}) => {
  const [countryList, setCountryList] = useState(initialCountries);
  const [isCountryLinksPage, setIsCountryLinksPage] = useState(false);
  const [isPaymentForAll, setIsPaymentForAll] = useState(false);
  const [textToClipboard, setTextToClipboard] = useState('');
  const redirectButtonCallback = () => {
    if ( isPaymentForAll ) {
      navigate(getGeneratedUrlForCountryResults('all'));
    } else {
      setIsCountryLinksPage(true);
    }
  };
  const [
    handleSubmitVoucher,
    isVoucherPopupOpen,
    voucherPopupOpenHandler,
    voucherPopupCloseHandler,
    error,
    setError,
    info,
    loading,
    price,
    setPrice,
    vouchersAmount,
    setInfo
  ] = useVoucherHandler(redirectButtonCallback, SINGLE_LOTTO_PRICE, 'Results');
  const VoucherForm = voucherFormVariant === 1 ? VoucherForm1 : VoucherForm2;

  useEffect(() => {
    const selected = getSelectedCountryList();
    if ( selected.length > 0 ) {
      let text = "Links:\n";
      for(const [name, info] of selected) {
        text += `${name}: ${window.location.origin}${getGeneratedUrlForCountryResults(info.slug)}\n`;
      }
      setTextToClipboard(text);
    }
  }, [isCountryLinksPage, countryList]);

  const infoHandler = () => {
    if ( info ) {
      if ( vouchersAmount >= price ) {
        setInfo(`You have entered ${formatBalance(vouchersAmount)} Do you want to see your results?`);
      } else {
        setInfo(`You have entered ${formatBalance(vouchersAmount)}. To show your Results, you need to enter another ${formatBalance(price - vouchersAmount)} voucher.`);
      }
    }
  };

  const getSelectedCountryList = () => countryList.filter(([, info]) => info.selected);
  const isDisableGetSelectResultButton = () => getSelectedCountryList().length < 1;

  const selectedOnClickHandler = () => {
    setIsPaymentForAll(false);
    const selected = getSelectedCountryList();
    if ( selected.length > 0 ) {
      setPrice(selected.length * SINGLE_LOTTO_PRICE);
      voucherPopupOpenHandler();
    }
  };

  const allOnClickHandler = () => {
    setIsPaymentForAll(true);
    setPrice(ALL_LOTTO_PRICE);
    voucherPopupOpenHandler();
  };

  return (
    <div className="app app__result-select">
      <div className="app__top">
        <Header isMenuEnabled={isMenuEnabled} />
        {
          isResultsHeaderOutside &&
          <div className="lotteries-results__header">
            <h5>Select A Country To Get Draw Results</h5>
          </div>
        }
        <main className="lotteries-results__wrapper">
          {
            !isResultsHeaderOutside &&
            <div className="lotteries-results__header">
              <h5>Select A Country To Get Draw Results</h5>
            </div>
          }
          <div className="lotteries-results__container country-wrapper">
            <LotteriesResultsCountriesView
              countryList={countryList}
              setCountryList={setCountryList}
              isCountryLinksPage={isCountryLinksPage}
            />
            {
              !isResultsCountryBtnOutside &&
              <>
                {
                  isCountryLinksPage ?
                    <CopyToClipboardButton textToCopy={textToClipboard}/>
                    :
                    <CountryResultButtonsWrapper
                      allOnClickHandler={allOnClickHandler}
                      selectedOnClickHandler={selectedOnClickHandler}
                      isDisableGetSelectResult={isDisableGetSelectResultButton()}
                    />
                }
              </>
            }
          </div>
          {
            isResultsCountryBtnOutside &&
            <>
              {
                isCountryLinksPage ?
                  <CopyToClipboardButton textToCopy={textToClipboard}/>
                  :
                  <CountryResultButtonsWrapper
                    allOnClickHandler={allOnClickHandler}
                    selectedOnClickHandler={selectedOnClickHandler}
                    isDisableGetSelectResult={isDisableGetSelectResultButton()}
                  />
              }
            </>
          }
        </main>
        <Link to='/' className={`back-to-main-btn ${backBtnCustomClass}`}>Back To Main Page</Link>
      </div>
      <Footer />
      {
        isVoucherPopupOpen &&
        <VoucherForm
          onClose={voucherPopupCloseHandler}
          onSubmitVoucher={handleSubmitVoucher}
          error={error}
          setError={setError}
          info={info}
          infoHandler={infoHandler}
          isSubmitOutside={voucherFormIsSubmitOutside}
          loading={loading}
          btnLabel='View results'
          voucherLabel={voucherLabel}
          title={<h5>Enter { formatBalance(price) } Voucher To Reveal</h5>}
          vouchersAmount={vouchersAmount}
          price={price}
          redirectButtonCallback={redirectButtonCallback}
        />
      }
    </div>
  );
};

export default ResultSelectPageTemplate;
