import React from 'react';
import menuItems from '../../config/menu';
import { Link } from 'gatsby';

const Menu = ({ items = menuItems }) => (
  <div className="menu-wrapper">
    <ul className="menu">
      {
        items && items.map(item => (
          <li key={item.id} className="menu__item">
            <Link
              to={item.slug}
              className="menu__item-link"
              activeClassName='menu__item-link__active'
              partiallyActive={item.partiallyActive}
            >
              {item.name}
            </Link>
          </li>
        ))
      }
    </ul>
  </div>
);

export default Menu;
