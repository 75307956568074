import React from 'react';

const Results = ({ results }) => (
  <ul className="lotteries-results__results">
    {
      results && results.map((result, index) => (
        <li key={result.id ? result.id : index} className={result.type === 'BONUS' ? 'active' : ''}>
          {result.value}
        </li>
      ))
    }
  </ul>
);

export default Results;
