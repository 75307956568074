import australia_lotto_6_from_45 from '../../libs/images/lotteries/australia_lotto_6_from_45.png';
import australia_oz_lotto from '../../libs/images/lotteries/australia_oz_lotto.png';
import austria_lotto from '../../libs/images/lotteries/austria_lotto.png';
import belgium_lotto from '../../libs/images/lotteries/belgium_lotto.png';
import barbados_mega_6 from '../../libs/images/lotteries/barbados_mega_6.png';
import barbados_super_lotto from '../../libs/images/lotteries/barbados_super_lotto.png';
import brazil_mega_sena from '../../libs/images/lotteries/brazil_mega_sena.png';
import bulgaria_toto_2_5_from_35 from '../../libs/images/lotteries/bulgaria_toto_2_5_from_35.png';
import bulgaria_toto_2_6_from_42 from '../../libs/images/lotteries/bulgaria_toto_2_6_from_42.png';
import bulgaria_toto_2_6_from_49 from '../../libs/images/lotteries/bulgaria_toto_2_6_from_49.png';
import bulgaria_toto_2_zodiac from '../../libs/images/lotteries/bulgaria_toto_2_zodiac.png';
import canada_atlantic from '../../libs/images/lotteries/canada_atlantic.png';
import canada_bc from '../../libs/images/lotteries/canada_bc.png';
import canada_main_6_from_49 from '../../libs/images/lotteries/canada_main_6_from_49.png';
import canada_ontario from '../../libs/images/lotteries/canada_ontario.png';
import canada_quebec from '../../libs/images/lotteries/canada_quebec.png';
import canada_western from '../../libs/images/lotteries/canada_western.png';
import finland_euro_jackpot from '../../libs/images/lotteries/finland_euro_jackpot.png';
import france_euromillions from '../../libs/images/lotteries/france_euromillions.png';
import france_loto from '../../libs/images/lotteries/france_loto.png';
import germany_lotto_6_from_49 from '../../libs/images/lotteries/germany_lotto_6_from_49.png';
import greece_jocker from '../../libs/images/lotteries/greece_jocker.png';
import greece_kino from '../../libs/images/lotteries/greece_kino.png';
import greece_lotto from '../../libs/images/lotteries/greece_lotto.png';
import ireland_daily_million_afternoon from '../../libs/images/lotteries/ireland_daily_million_afternoon.png';
import ireland_daily_million_evening from '../../libs/images/lotteries/ireland_daily_million_evening.png';
import ireland_daily_million_plus_afternoon from '../../libs/images/lotteries/ireland_daily_million_plus_afternoon.png';
import ireland_daily_million_plus_evening from '../../libs/images/lotteries/ireland_daily_million_plus_evening.png';
import ireland_lotto_plus_1 from '../../libs/images/lotteries/ireland_lotto_plus_1.png';
import ireland_lotto_plus_2 from '../../libs/images/lotteries/ireland_lotto_plus_2.png';
import ireland_main from '../../libs/images/lotteries/ireland_main.png';
import italy_10e_lotto from '../../libs/images/lotteries/italy_10e_lotto.png';
import italy_superenalotto from '../../libs/images/lotteries/italy_superenalotto.png';
import italy_win_for_life_classico from '../../libs/images/lotteries/italy_win_for_life_classico.png';
import japan_loto_6 from '../../libs/images/lotteries/japan_loto_6.png';
import lithuania_jega from '../../libs/images/lotteries/lithuania_jega.png';
import mauritius_loterie from '../../libs/images/lotteries/mauritius_loterie.png';
import nzl_lotto_6_from_40 from '../../libs/images/lotteries/nzl_lotto_6_from_40.png';
import poland_daily_mini from '../../libs/images/lotteries/poland_daily_mini.png';
import poland_main_6_from_49 from '../../libs/images/lotteries/poland_main_6_from_49.png';
import poland_plus_6_from_49 from '../../libs/images/lotteries/poland_plus_6_from_49.png';
import poland_ekstra_premia from '../../libs/images/lotteries/poland_ekstra_premia.svg';
import poland_ekstra_pencja from '../../libs/images/lotteries/poland_ekstra_pencja.svg';
import poland_szybkie_600 from '../../libs/images/lotteries/poland_szybkie_600.svg';
import poland_keno from '../../libs/images/lotteries/poland_keno.svg';
import powerball from '../../libs/images/lotteries/powerball.png';
import powerball_plus from '../../libs/images/lotteries/powerball_plus.png';
import rsa_daily_lotto from '../../libs/images/lotteries/rsa_daily_lotto.png';
import rsa_lotto from '../../libs/images/lotteries/rsa_lotto.png';
import rsa_lotto_plus_1 from '../../libs/images/lotteries/rsa_lotto_plus_1.png';
import rsa_lotto_plus_2 from '../../libs/images/lotteries/rsa_lotto_plus_2.png';
import russia_12x24 from '../../libs/images/lotteries/russia_12x24.png';
import russia_am_gosloto_6_from_45 from '../../libs/images/lotteries/russia_am_gosloto_6_from_45.png';
import russia_gosloto_5_from_36 from '../../libs/images/lotteries/russia_gosloto_5_from_36.png';
import russia_gosloto_7_from_49 from '../../libs/images/lotteries/russia_gosloto_7_from_49.png';
import russia_keno from '../../libs/images/lotteries/russia_keno.png';
import russia_pm_gosloto_6_from_45 from '../../libs/images/lotteries/russia_pm_gosloto_6_from_45.png';
import russia_rapido from '../../libs/images/lotteries/russia_rapido.png';
import russia_rapido_2 from '../../libs/images/lotteries/russia_rapido_2.png';
import singapore_toto from '../../libs/images/lotteries/singapore_toto.png';
import slovakia_eklub_keno from '../../libs/images/lotteries/slovakia_eklub_keno.png';
import spain_daily_6_from_49 from '../../libs/images/lotteries/spain_daily_6_from_49.png';
import spain_main_6_from_49 from '../../libs/images/lotteries/spain_main_6_from_49.png';
import sweden_lotto_1 from '../../libs/images/lotteries/sweden_lotto_1.png';
import sweden_lotto_2 from '../../libs/images/lotteries/sweden_lotto_2.png';
import switzerland_powerball from '../../libs/images/lotteries/switzerland_powerball.png';
import turkey_lotto from '../../libs/images/lotteries/turkey_lotto.png';
import uk_49s_lunch from '../../libs/images/lotteries/uk_49s_lunch.png';
import uk_49s_tea from '../../libs/images/lotteries/uk_49s_tea.png';
import uk_main from '../../libs/images/lotteries/uk_main.png';
import uk_thunderball from '../../libs/images/lotteries/uk_thunderball.png';
import usa_virginia_cash_5_day from '../../libs/images/lotteries/usa_virginia_cash_5_day.png';
import usa_ohio_classic_lotto from '../../libs/images/lotteries/usa_ohio_classic_lotto.png';
import usa_kentucky_keno from '../../libs/images/lotteries/usa_kentucky_keno.png';
import usa_connecticut_cash5 from '../../libs/images/lotteries/usa_connecticut_cash5.png';
import usa_florida_fantasy_5 from '../../libs/images/lotteries/usa_florida_fantasy_5.png';
import usa_missouri_lotto from '../../libs/images/lotteries/usa_missouri_lotto.png';
import usa_north_carolina_cash_5 from '../../libs/images/lotteries/usa_north_carolina_cash_5.png';
import usa_powerball from '../../libs/images/lotteries/usa_powerball.png';
import usa_maryland_multi_match from '../../libs/images/lotteries/usa_maryland_multi_match.png';
import usa_new_jersey_cash_5 from '../../libs/images/lotteries/usa_new_jersey_cash_5.png';
import usa_megamillions from '../../libs/images/lotteries/usa_megamillions.png';
import usa_nj_quick_draw from '../../libs/images/lotteries/usa_nj_quick_draw.png';
import usa_georgia_jumbo_bucks from '../../libs/images/lotteries/usa_georgia_jumbo_bucks.png';
import usa_tri_state_megabucks_plus from '../../libs/images/lotteries/usa_tri_state_megabucks_plus.png';
import usa_massachusetts_megabucks_doubler from '../../libs/images/lotteries/usa_massachusetts_megabucks_doubler.png';
import usa_ohio_rolling_cash from '../../libs/images/lotteries/usa_ohio_rolling_cash.png';
import usa_massachusetts_mass_cash from '../../libs/images/lotteries/usa_massachusetts_mass_cash.png';
import usa_south_carolina_palmetto_cash_5 from '../../libs/images/lotteries/usa_south_carolina_palmetto_cash_5.png';
import usa_rhode_island_keno from '../../libs/images/lotteries/usa_rhode_island_keno.png';
import usa_indiana_hoosier_lotto from '../../libs/images/lotteries/usa_indiana_hoosier_lotto.png';
import usa_virginia_bank_a_million from '../../libs/images/lotteries/usa_virginia_bank_a_million.png';
import usa_kentucky_cash_ball from '../../libs/images/lotteries/usa_kentucky_cash_ball.png';
import usa_texas_lotto from '../../libs/images/lotteries/usa_texas_lotto.png';
import usa_louisiana_lotto from '../../libs/images/lotteries/usa_louisiana_lotto.png';
import usa_new_york_lotto from '../../libs/images/lotteries/usa_new_york_lotto.png';
import usa_california_superlotto_plus from '../../libs/images/lotteries/usa_california_superlotto_plus.png';
import usa_kansas_super_cash from '../../libs/images/lotteries/usa_kansas_super_cash.png';
import usa_maryland_bonus_match_5 from '../../libs/images/lotteries/usa_maryland_bonus_match_5.png';
import usa_colorado_lotto from '../../libs/images/lotteries/usa_colorado_lotto.png';
import usa_connecticut_classic from '../../libs/images/lotteries/usa_connecticut_classic.png';
import usa_florida_lotto from '../../libs/images/lotteries/usa_florida_lotto.png';
import usa_arkansas_state_jackpot from '../../libs/images/lotteries/usa_arkansas_state_jackpot.png';
import usa_georgia_fantasy_5 from '../../libs/images/lotteries/usa_georgia_fantasy_5.png';
import usa_tri_state_gimme_5 from '../../libs/images/lotteries/usa_tri_state_gimme_5.png';
import usa_texas_two_step from '../../libs/images/lotteries/usa_texas_two_step.png';
import usa_texas_cash_five from '../../libs/images/lotteries/usa_texas_cash_five.png';
import usa_montana_cash from '../../libs/images/lotteries/usa_montana_cash.png';
import usa_arizona_the_pick from '../../libs/images/lotteries/usa_arizona_the_pick.png';
import usa_louisiana_easy_5 from '../../libs/images/lotteries/usa_louisiana_easy_5.png';
import usa_tennessee_keno_to_go from '../../libs/images/lotteries/usa_tennessee_keno_to_go.png';
import usa_virginia_cash_5_night from '../../libs/images/lotteries/usa_virginia_cash_5_night.png';
import usa_michigan_lotto_47 from '../../libs/images/lotteries/usa_michigan_lotto_47.png';
import usa_idaho_weekly_grand from '../../libs/images/lotteries/usa_idaho_weekly_grand.png';
import usa_west_virginia_cash_25 from '../../libs/images/lotteries/usa_west_virginia_cash_25.png';
import usa_lucky_for_life from '../../libs/images/lotteries/usa_lucky_for_life.png';
import usa_new_york_take_5 from '../../libs/images/lotteries/usa_new_york_take_5.png';
import usa_new_jersey_pick_6 from '../../libs/images/lotteries/usa_new_jersey_pick_6.png';

const lotteries = {
  australia_lotto_6_from_45: australia_lotto_6_from_45,
  australia_oz_lotto: australia_oz_lotto,
  austria_lotto: austria_lotto,
  belgium_lotto: belgium_lotto,
  barbados_mega_6: barbados_mega_6,
  barbados_super_lotto: barbados_super_lotto,
  brazil_mega_sena: brazil_mega_sena,
  bulgaria_toto_2_5_from_35: bulgaria_toto_2_5_from_35,
  bulgaria_toto_2_6_from_42: bulgaria_toto_2_6_from_42,
  bulgaria_toto_2_6_from_49: bulgaria_toto_2_6_from_49,
  bulgaria_toto_2_zodiac: bulgaria_toto_2_zodiac,
  canada_atlantic: canada_atlantic,
  canada_bc: canada_bc,
  canada_main_6_from_49: canada_main_6_from_49,
  canada_ontario: canada_ontario,
  canada_quebec: canada_quebec,
  canada_western: canada_western,
  finland_euro_jackpot: finland_euro_jackpot,
  france_euromillions: france_euromillions,
  france_loto: france_loto,
  germany_lotto_6_from_49: germany_lotto_6_from_49,
  greece_jocker: greece_jocker,
  greece_kino: greece_kino,
  greece_lotto: greece_lotto,
  ireland_daily_million_afternoon: ireland_daily_million_afternoon,
  ireland_daily_million_evening: ireland_daily_million_evening,
  ireland_daily_million_plus_afternoon: ireland_daily_million_plus_afternoon,
  ireland_daily_million_plus_evening: ireland_daily_million_plus_evening,
  ireland_lotto_plus_1: ireland_lotto_plus_1,
  ireland_lotto_plus_2: ireland_lotto_plus_2,
  ireland_main: ireland_main,
  italy_10e_lotto: italy_10e_lotto,
  italy_superenalotto: italy_superenalotto,
  italy_win_for_life_classico: italy_win_for_life_classico,
  japan_loto_6: japan_loto_6,
  lithuania_jega: lithuania_jega,
  mauritius_loterie: mauritius_loterie,
  nzl_lotto_6_from_40: nzl_lotto_6_from_40,
  poland_daily_mini: poland_daily_mini,
  poland_main_6_from_49: poland_main_6_from_49,
  poland_plus_6_from_49: poland_plus_6_from_49,
  poland_ekstra_pensja: poland_ekstra_pencja,
  poland_ekstra_premia: poland_ekstra_premia,
  poland_szybkie_600: poland_szybkie_600,
  poland_keno: poland_keno,
  powerball: powerball,
  powerball_plus: powerball_plus,
  rsa_daily_lotto: rsa_daily_lotto,
  rsa_lotto: rsa_lotto,
  rsa_lotto_plus_1: rsa_lotto_plus_1,
  rsa_lotto_plus_2: rsa_lotto_plus_2,
  russia_12x24: russia_12x24,
  russia_am_gosloto_6_from_45: russia_am_gosloto_6_from_45,
  russia_gosloto_5_from_36: russia_gosloto_5_from_36,
  russia_gosloto_7_from_49: russia_gosloto_7_from_49,
  russia_keno: russia_keno,
  russia_pm_gosloto_6_from_45: russia_pm_gosloto_6_from_45,
  russia_rapido: russia_rapido,
  russia_rapido_2: russia_rapido_2,
  singapore_toto: singapore_toto,
  slovakia_eklub_keno: slovakia_eklub_keno,
  spain_daily_6_from_49: spain_daily_6_from_49,
  spain_main_6_from_49: spain_main_6_from_49,
  sweden_lotto_1: sweden_lotto_1,
  sweden_lotto_2: sweden_lotto_2,
  switzerland_powerball: switzerland_powerball,
  turkey_lotto: turkey_lotto,
  uk_49s_lunch: uk_49s_lunch,
  uk_49s_tea: uk_49s_tea,
  uk_main: uk_main,
  uk_thunderball: uk_thunderball,
  usa_virginia_cash_5_day: usa_virginia_cash_5_day,
  usa_ohio_classic_lotto: usa_ohio_classic_lotto,
  usa_kentucky_keno: usa_kentucky_keno,
  usa_connecticut_cash5: usa_connecticut_cash5,
  usa_florida_fantasy_5: usa_florida_fantasy_5,
  usa_missouri_lotto: usa_missouri_lotto,
  usa_north_carolina_cash_5: usa_north_carolina_cash_5,
  usa_powerball: usa_powerball,
  usa_maryland_multi_match: usa_maryland_multi_match,
  usa_new_jersey_cash_5: usa_new_jersey_cash_5,
  usa_megamillions: usa_megamillions,
  usa_nj_quick_draw: usa_nj_quick_draw,
  usa_georgia_jumbo_bucks: usa_georgia_jumbo_bucks,
  usa_tri_state_megabucks_plus: usa_tri_state_megabucks_plus,
  usa_massachusetts_megabucks_doubler: usa_massachusetts_megabucks_doubler,
  usa_ohio_rolling_cash: usa_ohio_rolling_cash,
  usa_massachusetts_mass_cash: usa_massachusetts_mass_cash,
  usa_south_carolina_palmetto_cash_5: usa_south_carolina_palmetto_cash_5,
  usa_rhode_island_keno: usa_rhode_island_keno,
  usa_indiana_hoosier_lotto: usa_indiana_hoosier_lotto,
  usa_virginia_bank_a_million: usa_virginia_bank_a_million,
  usa_kentucky_cash_ball: usa_kentucky_cash_ball,
  usa_texas_lotto: usa_texas_lotto,
  usa_louisiana_lotto: usa_louisiana_lotto,
  usa_new_york_lotto: usa_new_york_lotto,
  usa_california_superlotto_plus: usa_california_superlotto_plus,
  usa_kansas_super_cash: usa_kansas_super_cash,
  usa_maryland_bonus_match_5: usa_maryland_bonus_match_5,
  usa_colorado_lotto: usa_colorado_lotto,
  usa_connecticut_classic: usa_connecticut_classic,
  usa_florida_lotto: usa_florida_lotto,
  usa_arkansas_state_jackpot: usa_arkansas_state_jackpot,
  usa_georgia_fantasy_5: usa_georgia_fantasy_5,
  usa_tri_state_gimme_5: usa_tri_state_gimme_5,
  usa_texas_two_step: usa_texas_two_step,
  usa_texas_cash_five: usa_texas_cash_five,
  usa_montana_cash: usa_montana_cash,
  usa_arizona_the_pick: usa_arizona_the_pick,
  usa_louisiana_easy_5: usa_louisiana_easy_5,
  usa_tennessee_keno_to_go: usa_tennessee_keno_to_go,
  usa_virginia_cash_5_night: usa_virginia_cash_5_night,
  usa_michigan_lotto_47: usa_michigan_lotto_47,
  usa_idaho_weekly_grand: usa_idaho_weekly_grand,
  usa_west_virginia_cash_25: usa_west_virginia_cash_25,
  usa_lucky_for_life: usa_lucky_for_life,
  usa_new_york_take_5: usa_new_york_take_5,
  usa_new_jersey_pick_6: usa_new_jersey_pick_6
};

export default lotteries;
