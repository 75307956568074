import axios from "./axios-instance";

export const sendEmail = (name, email, message, hostname) => {
  const params = {
    name: name,
    email: email,
    message: message,
    hostname: hostname
  };
  return axios.post('/mail', params, {
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    }
  });
};
