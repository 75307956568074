import React, { useEffect, useState } from 'react';
import Country from '../../../components/Country';
import CountryLink from '../../../components/Country/CountryLink';
import { RESULT_PAGE_SIZE_CONFIG } from '../../../config/breakpoints';
import flags from '../../../helpers/flags';
import { getGeneratedUrlForCountryResults } from '../../../helpers/utils';

const LotteriesResultsCountriesView = ({
  countryList,
  setCountryList,
  isCountryLinksPage = false
}) => {
  const [countryItemPerRows, setCountryItemPerRows] = useState(8);

  useEffect(() => {
    const isMatchMediaQuery = size => window.innerWidth >= size;

    const resizeHandler = () => {
      for (let i = RESULT_PAGE_SIZE_CONFIG.length - 1; i >= 0; i--) {
        if ( isMatchMediaQuery(RESULT_PAGE_SIZE_CONFIG[i].size) ) {
          setCountryItemPerRows(RESULT_PAGE_SIZE_CONFIG[i].itemPerRows);
          break;
        }
      }
    };

    resizeHandler();
    window.addEventListener('resize', resizeHandler);

    return () => window.removeEventListener('resize', resizeHandler);
  }, []);

  const checkIfCountryImageExists = slug => {
    if ( flags.hasOwnProperty(slug) ) {
      return flags[slug];
    }
    return null;
  };

  const countriesDisplay = () => {
    const result = [];
    let countryCount = 0;
    let rowData = [];
    let rowCount = 0;
    for(const [country, countryInfo] of countryList) {
      rowData.push(
        <Country
          slug={countryInfo.slug}
          src={checkIfCountryImageExists(countryInfo.slug)}
          name={country}
          key={countryCount}
          isSelected={countryInfo.selected}
          onClickHandler={() => {
            const newSelectedCountriesList = countryList.map(countryItem => {
              const [name, info] = countryItem;
              return info.slug === countryInfo.slug ? [
                name,
                {
                  slug: info.slug,
                  selected: !info.selected
                }
              ] : countryItem;
            });
            setCountryList(newSelectedCountriesList);
          }}
        />
      );
      countryCount++;
      if ( countryCount%countryItemPerRows===0 ) {
        result.push(
          <div key={rowCount} className="country__row">
            {rowData}
          </div>
        );
        rowCount++;
        rowData = [];
      }
    }

    result.push(
      <div key={rowCount} className="country__row">
        {rowData}
      </div>
    );

    return result;
  };

  const countriesLinkDisplay = () => {
    const result = [];
    let countryCount = 0;
    let rowData = [];
    let rowCount = 0;
    const filteredList = countryList.filter(([, info]) => info.selected);
    for(const [country, countryInfo] of filteredList) {
      rowData.push(
        <CountryLink
          key={countryInfo.slug}
          src={checkIfCountryImageExists(countryInfo.slug)}
          name={country}
          href={getGeneratedUrlForCountryResults(countryInfo.slug)}
        />
      );
      countryCount++;
      if ( countryCount%countryItemPerRows===0 ) {
        result.push(
          <div key={rowCount} className="country__row">
            {rowData}
          </div>
        );
        rowCount++;
        rowData = [];
      }
    }

    result.push(
      <div key={rowCount} className="country__row">
        {rowData}
      </div>
    );

    return result;
  };

  return (
    <>
      {
        isCountryLinksPage ?
          countriesLinkDisplay():
          countriesDisplay()
      }
    </>
  );
};

export default LotteriesResultsCountriesView;
