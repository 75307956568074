import React from 'react';
import flags from '../../helpers/flags';
import LotteriesResultsHeaderDefault from '../components/LotteriesResultsHeader';
import LotteriesResultsHeaderSecond from '../components/LotteriesResultsHeader/LotteriesResultsHeaderSecond';
import Layout from "../Layout";
import { getCountriesResults } from '../../helpers/countriesResults';

const CountriesLotteryResults = ({
  pageContext: {
    lotteries,
    countryName,
    countrySlug,
    headerType = 'default',
    isMenuEnabled = false
  }
}) => (
  <Layout
    appCustomClass='app-lotteries'
    mainCustomClass='lotteries-results__wrapper lotteries-results__wrapper-country'
    isHomepageLinkEnabled={true}
    isMenuEnabled={isMenuEnabled}
  >
    {
      headerType === 'default' ?
        <LotteriesResultsHeaderDefault
          flagSrc={flags.hasOwnProperty(countrySlug) ? flags[countrySlug] : null}
          countryName={countryName}
        /> :
        <LotteriesResultsHeaderSecond
          flagSrc={flags.hasOwnProperty(countrySlug) ? flags[countrySlug] : null}
          countryName={countryName}
        />
    }
    <div className="lotteries-results__container">
      {getCountriesResults(lotteries)}
    </div>
  </Layout>
);

export default CountriesLotteryResults;
