const addLeadingZero = number => {
  return number < 10 ? `0${number}` : number;
};

const formatDate = inputDate => {
  const date = new Date(inputDate);
  return `${addLeadingZero(date.getDate())}.${addLeadingZero(date.getMonth() + 1)}.${date.getFullYear()} ${addLeadingZero(date.getHours())}:${addLeadingZero(date.getMinutes())}`;
};

const formatPostDate = inputDate => {
  const date = new Date(inputDate);
  return `${addLeadingZero(date.getDate())}.${addLeadingZero(date.getMonth() + 1)}.${date.getFullYear()}`;
};

const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

const formatCommentDate = inputDate => {
  const date = new Date(inputDate);
  return `${monthNames[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()} at ${addLeadingZero(date.getHours())}:${addLeadingZero(date.getMinutes())}`;
};

module.exports.addLeadingZero = addLeadingZero;
module.exports.formatDate = formatDate;
module.exports.formatPostDate = formatPostDate;
module.exports.formatCommentDate = formatCommentDate;
