const countries = {
  'Australia': {
    slug: 'au',
    selected: false
  },
  'Austria': {
    slug: 'at',
    selected: false
  },
  'Barbados': {
    slug: 'bb',
    selected: false
  },
  'Bulgaria': {
    slug: 'bg',
    selected: false
  },
  'Belgium': {
    slug: 'be',
    selected: false
  },
  'Brazil': {
    slug: 'br',
    selected: false
  },
  'Canada': {
    slug: 'ca',
    selected: false
  },
  'Finland': {
    slug: 'fi',
    selected: false
  },
  'France': {
    slug: 'fr',
    selected: false
  },
  'Germany': {
    slug: 'de',
    selected: false
  },
  'Greece': {
    slug: 'gr',
    selected: false
  },
  'Ireland': {
    slug: 'ie',
    selected: false
  },
  'Italy': {
    slug: 'it',
    selected: false
  },
  'Japan': {
    slug: 'jp',
    selected: false
  },
  'Lithuania': {
    slug: 'lt',
    selected: false
  },
  'Mauritius': {
    slug: 'mu',
    selected: false
  },
  'New Zealand': {
    slug: 'nz',
    selected: false
  },
  'Poland': {
    slug: 'pl',
    selected: false
  },
  'Republic of South Africa': {
    slug: 'za',
    selected: false
  },
  'Russia': {
    slug: 'ru',
    selected: false
  },
  'Singapore': {
    slug: 'sg',
    selected: false
  },
  // 'Slovakia': {
  //   slug: 'sk'
  // },
  'Spain': {
    slug: 'es',
    selected: false
  },
  'Sweden': {
    slug: 'se',
    selected: false
  },
  'Switzerland': {
    slug: 'ch',
    selected: false
  },
  // 'Turkey': {
  //   slug: 'tr'
  // },
  'United Kingdom': {
    slug: 'uk',
    selected: false
  },
  'United States': {
    slug: 'us',
    selected: false
  }
};

module.exports.countries = countries;
