import React, { useState, useRef, useEffect } from 'react';
import Input from '../../FormElements/Input';
import TextArea from '../../FormElements/TextArea';
import Loader from '../../Loader';
import { sendEmail } from '../../../api/mailgun';

const FormPopup = ({
  title = <h4>Contact us<br />for advertising and backlinks</h4>,
  closeHandler,
  isLabelEnabled = true,
  namePlaceholder = "Enter your name",
  nameLabel = "Name:",
  emailPlaceholder = "Enter your email",
  emailLabel = "Email:",
  messagePlaceholder = "Enter your message",
  messageLabel = "Message:"
}) => {
  const nameInput = useRef();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState(null);
  const [info, setInfo] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (nameInput.current) {
      nameInput.current.focus();
    }
  }, [nameInput]);

  const handleChangeName = (name) => {
    if (name.length < 200) {
      setName(name)
    }
  };

  const handleChangeEmail = (email) => {
    if (email.length < 200) {
      setEmail(email)
    }
  };

  const isFormValid = () => {
    return name.length && isEmailValid(email) && message.length;
  };

  const isEmailValid = (email) => {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
  };

  const resetForm = () => {
    setName('');
    setEmail('');
    setMessage('');
  };

  const handleSubmit = event => {
    event.preventDefault();
    setLoading(true);

    sendEmail(name, email, message, window.location.hostname).then(response => {
      resetForm();
      setError(null);
      if ( response.data && response.data.message && response.data.message === 'Message has been sent' ) {
        setInfo('Message has been successfully sent');
      } else {
        setError('Something went wrong. Try again later.');
      }
      setLoading(false);
    }).catch(error => {
      setError('Something went wrong. Try again later');
      setInfo(null);
      setLoading(false);
    });
  };

  return (
    <>
      <form className="contact-form" onSubmit={handleSubmit}>
        <div className="contact-form__inner">
          <div className="contact-form__fields">
            {title}
            <div className="contact-form__field">
              {isLabelEnabled && <label htmlFor="contact-form__name">{nameLabel}</label>}
              <Input
                refLink={nameInput}
                type="text"
                id="contact-form__name"
                placeholder={namePlaceholder}
                onChange={handleChangeName}
                value={name}
                error={!name.length}
                name='name'
              />
            </div>
            <div className="contact-form__field">
              {isLabelEnabled && <label htmlFor="contact-form__email">{emailLabel}</label>}
              <Input
                type="text"
                id="contact-form__email"
                placeholder={emailPlaceholder}
                onChange={handleChangeEmail}
                value={email}
                error={!isEmailValid(email)}
                name="email"
              />
            </div>
            <div className="contact-form__field">
              {isLabelEnabled && <label htmlFor="contact-form__message">{messageLabel}</label>}
              <TextArea
                id="contact-form__message"
                placeholder={messagePlaceholder}
                onChange={setMessage}
                value={message}
                error={!message.length}
                name="message"
              />
            </div>
            {error &&
            <p className='contact-form__error'>{error}</p>}
            {info &&
            <p className='contact-form__info'>{info}</p>}
          </div>
          <button type='submit' className='contact-form__submit' disabled={!isFormValid()}>Send</button>
        </div>
        <button type="button" className="contact-form__close" onClick={closeHandler}>
          <span />
        </button>
        {loading && <Loader />}
      </form>
      <div className="contact-form__overlay" onClick={closeHandler} />
    </>
  );
};

export default FormPopup;
