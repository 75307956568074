import React from 'react';

const LotteriesResultsHeaderSecond = ({ flagSrc, countryName }) => (
  <div className="lotteries-results__header">
    <div className="lotteries-results__header-left">
      {
        flagSrc &&
        <div>
          <div className='lotteries-results__header-flag' style={{
            backgroundImage: `url(${flagSrc})`
          }}>
            {/*<img src={flagSrc} alt={countryName}/>*/}
          </div>
        </div>
      }
      <h5>{countryName}</h5>
    </div>
    <div className="lotteries-results__header-right">
      <h5>Lottery Results</h5>
    </div>
  </div>
);

export default LotteriesResultsHeaderSecond;
