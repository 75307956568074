import React from 'react';
import LotteryInfo from '../LotteryInfo';
import Results from '../Results';
import lotteryLogos from '../../../helpers/lotteries';

const LotteryRow = ({ lottery, countryDisable = false }) => {
  const lottaryNameCountryRemove = (country, name) => {
    const re = new RegExp(`${country} - `, 'i');
    return name.replace(re, '').toLowerCase();
  };

  const getLotteryLogo = drawId => {
    if ( lotteryLogos.hasOwnProperty(drawId) ) {
      return lotteryLogos[drawId];
    }
    return null;
  };

  return (
    <div className="lotteries-results__row">
      <LotteryInfo
        date={lottery?.draw_date}
        country={countryDisable ? false : lottery?.country}
        lotteryLogoSrc={getLotteryLogo(lottery?.draw_id)}
        lotteryName={lottery.draw_name && lottaryNameCountryRemove(lottery.country, lottery.draw_name) }
      />
      <Results results={lottery?.results} />
    </div>
  );
};

export default LotteryRow;
