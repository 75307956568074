import React, {useEffect, useRef, useState} from 'react';
import Input from '../Input';
import { formatVoucher } from '../../../helpers/voucher';

const VoucherInput = ({id, onChange, value, focus}) => {
  const voucherInput = useRef();
  const [caret, setCaret] = useState(null);

  useEffect(() => {
    if (focus && voucherInput.current) {
      voucherInput.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (caret !== null) {
      let newCaretPosition = caret;
      if (newCaretPosition < 0) {
        newCaretPosition *= -1;
      }
      if (value[newCaretPosition - 1] === ' ') {
        if (caret < 0) {
          newCaretPosition--;
        } else {
          newCaretPosition++;
        }
      }
      setCaretPosition(newCaretPosition);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleChange = (changedValue) => {
    let newValue = changedValue;
    if (changedValue.length < 20) {
      let caretPosition = getCaretPosition();
      if (caretPosition || caretPosition === 0) {
        if (changedValue.length < value.length) {
          if (value[caretPosition] === ' ') {
            newValue = newValue.slice(0, caretPosition - 1) + newValue.slice(caretPosition);
            caretPosition--;
          }
          caretPosition *= -1;
        }
        setCaret(caretPosition);
      }
      onChange(formatVoucher(newValue));
    }
  };

  const getCaretPosition = () => {
    if (voucherInput.current && (voucherInput.current.selectionStart || voucherInput.current.selectionStart === '0')) {
      return voucherInput.current.selectionStart;
    }
    return 0;
  };

  const setCaretPosition = (position) => {
    if (voucherInput.current && voucherInput.current.setSelectionRange) {
      voucherInput.current.focus();
      voucherInput.current.setSelectionRange(position, position);
    }
  };

  return (
    <Input
      refLink={voucherInput}
      type="text"
      id={id}
      placeholder="Enter Your Voucher"
      onChange={handleChange}
      value={value}
      pattern='\+?[ 0-9]*'
      error={value.length < 19}
      inputMode='numeric'
    />
  );
};

export default React.memo(VoucherInput);
