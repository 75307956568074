import React from 'react';

const Lottery = ({ title, src, customClass = '' }) => (
  <div className={"lottery " + customClass}>
    <div className="lottery__image-wrapper">
      <img src={src} className='lottery__image' alt={title}/>
    </div>
    <h6 className="lottery__title">{title}</h6>
  </div>
);

export default Lottery;
