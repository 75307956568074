import React from 'react';

const LotterySection = props => (
  <section className="lottery-section">
    <div className="container">
      <div className="row">{props.children}</div>
    </div>
  </section>
);

export default LotterySection;
