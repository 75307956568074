import React from "react";
import LotteryRow from "../templates/components/LotteryRow";

export const getAllCountriesResults = lotteries => {
  const result = [];
  if ( lotteries ) {
    for (const property in lotteries) {
      if ( !lotteries.hasOwnProperty(property) ) {
        continue;
      }
      const country = lotteries[property];
      for(const lotteryName in country) {
        if ( !country.hasOwnProperty(lotteryName) && Array.isArray(country[lotteryName]) ) {
          continue;
        }
        country[lotteryName].map(lotteryDraw => {
          if (
            lotteryDraw.results &&
            lotteryDraw.draw_name &&
            lotteryDraw.draw_id &&
            lotteryDraw.country &&
            lotteryDraw.draw_date
          ) {
            result.push(
              <LotteryRow key={`${lotteryDraw.id}-${lotteryDraw.draw_id}`} lottery={lotteryDraw} />
            );
          }
        });
      }
    }
  }

  return result;
};

export const getCountriesResults = lotteries => {
  const result = [];
  for(const lotteryName in lotteries) {
    if ( !lotteries.hasOwnProperty(lotteryName) && Array.isArray(lotteries[lotteryName]) ) {
      continue;
    }
    lotteries[lotteryName].map(lotteryDraw => {
      if (
        lotteryDraw.results &&
        lotteryDraw.draw_name &&
        lotteryDraw.draw_id &&
        lotteryDraw.draw_date
      ) {
        result.push(
          <LotteryRow key={`${lotteryDraw.id}-${lotteryDraw.draw_id}`} lottery={lotteryDraw} countryDisable={true}/>
        );
      }
    });
  }

  return result;
};
