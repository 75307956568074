import React, { useState } from 'react';

const Input = ({refLink, id, type, placeholder, onChange, value, pattern, error, infoMessage, inputMode, name = ''}) => {
  const [lostFocus, setLostFocus] = useState(false);

  const onBlurHandler = () => {
    if (!lostFocus) {
      setLostFocus(true);
    }
  };

  const handleChange = (event) => {
    if (event.target.validity.valid && event.target.value.length < 120) {
      if (!event.target.value.replace(/[\s-]/g, '').length) {
        onChange('');
      } else {
        onChange(event.target.value);
      }
    }
  };

  return (
    <>
      <input
        ref={refLink}
        className={'form-input' + (error && lostFocus ? (' form-input__error') : '')}
        type={type}
        id={id}
        placeholder={placeholder}
        onChange={handleChange}
        value={value}
        pattern={pattern}
        inputMode={inputMode}
        onBlur={onBlurHandler}
        name={name}
      />
      {infoMessage &&
      <p className='form-info-message'>{infoMessage}</p>
      }
    </>
  );
};

export default React.memo(Input);
