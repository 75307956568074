import React from 'react';

const CountryLink = ({ name, src, href }) => (
  <div className="country">
    <a target="_blank" href={href}>
      <div className="country__image-wrapper" style={{
        backgroundImage: `url(${src})`
      }} />
    </a>
    <h6><a target="_blank" href={href}>{name}</a></h6>
  </div>
);

export default CountryLink;
