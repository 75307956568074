const menuItems = [
  {
    id: 'home-page',
    slug: '/',
    name: 'Home',
    partiallyActive: false
  },
  {
    id: 'results-page',
    slug: '/result-select/',
    name: 'Results',
    partiallyActive: true
  },
  {
    id: 'blog-page',
    slug: '/blog/',
    name: 'Blog',
    partiallyActive: true
  },
  {
    id: 'category-reviews-page',
    slug: '/category/reviews/',
    name: 'Reviews',
    partiallyActive: true
  }
];

export default menuItems;
