import { addLeadingZero } from './format';
import md5 from 'md5';

export const getGeneratedUrlForCountryResults = countrySlug => {
  const date = new Date();
  const currentDate = `${addLeadingZero(date.getUTCDate())}-${addLeadingZero(date.getUTCMonth() + 1)}-${date.getUTCFullYear()}`;
  const hashForCountry = md5(currentDate + countrySlug + process.env.REACT_MERCHANT_NAME);

  return `/results/${countrySlug}/${currentDate}-${hashForCountry}`;
};
