import React from 'react';
import Layout from "../Layout";
import { getAllCountriesResults } from '../../helpers/countriesResults';

const AllCountriesLotteryResults = ({
  pageContext: {
    lotteries: lotteries,
    isMenuEnabled = false
  }
}) => {

  return (
    <Layout
      appCustomClass='app-lotteries'
      mainCustomClass='lotteries-results__wrapper  lotteries-results__wrapper-all-country'
      isHomepageLinkEnabled={true}
      isMenuEnabled={isMenuEnabled}
    >
      <div className="lotteries-results__header">
        <h5>All Countries Lottery Results</h5>
      </div>
      <div className="lotteries-results__container">
        {getAllCountriesResults(lotteries)}
      </div>
    </Layout>
  );
};

export default AllCountriesLotteryResults;
