import axios from "./axios-instance";

export const redeemVoucher = (voucher) => {
  const params = {
    number: voucher,
    merchantName: process.env.REACT_MERCHANT_NAME
  };
  return axios.post('/acoin', params, {
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    }
  });
};

export const handleError = (errorResponse) => {
  let errorMessage = 'An unknown error occurred!';
  if (errorResponse && errorResponse.data && errorResponse.data.error_code) {
    switch (errorResponse.data.error_code) {
      case '5031':
        errorMessage = 'Invalid Voucher.';
        break;
      default:
        errorMessage = errorResponse.data.message;
    }
  }
  return errorMessage;
};
