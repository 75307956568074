import React from 'react';

const Country = ({ name, src, onClickHandler, isSelected = false }) => (
  <div className={`country ${isSelected ? 'selected' : ''}`} onClick={onClickHandler}>
    {
      src &&
      <div className="country__image-wrapper" style={{
        backgroundImage: `url(${src})`
      }}>
        {
          !isSelected &&
          <div className="country__overlay">
            <h6>Get Results</h6>
          </div>
        }
        {
          isSelected &&
          <div className="country__selected">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23px" height="19px">
              <path fillRule="evenodd"  fill="rgb(255, 255, 255)"
                    d="M23.011,3.793 L8.020,19.012 L-0.012,10.859 L3.736,7.054 L8.020,11.402 L19.264,-0.011 L23.011,3.793 Z"/>
            </svg>
          </div>
        }
      </div>
    }
    <h6>{name}</h6>
  </div>
);

export default Country;
