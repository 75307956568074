import React, { useState } from 'react';
import * as api from '../api/acoin';
import { formatBalance } from '../helpers/voucher';

const useVoucherHandler = (
  onSuccessCallback,
  defaultPrice = 1000,
  productName = 'Lucky Numbers'
) => {
  const [isVoucherPopupOpen, setIsVoucherPopupOpen] = useState(false);
  const [vouchersAmount, setVouchersAmount] = useState(0);
  const [error, setError] = useState(null);
  const [info, setInfo] = useState(null);
  const [price, setPrice] = useState(defaultPrice);
  const [loading, setLoading] = useState(false);

  const resetVoucher = () => {
    setIsVoucherPopupOpen(false);
    setVouchersAmount(0);
  };

  const voucherPopupOpenHandler = () => setIsVoucherPopupOpen(true);
  const voucherPopupCloseHandler = () => setIsVoucherPopupOpen(false);

  const handleSubmitVoucher = (voucherCode) => {
    setLoading(true);
    api.redeemVoucher(voucherCode).then(response => {
      if (!response.data || !response.data.success) {
        setError(api.handleError(response));
      } else {
        if (response.data.value >= price || (response.data.value + vouchersAmount) >= price || vouchersAmount >= price) {
          onSuccessCallback();
          setIsVoucherPopupOpen(false);
          setInfo(null);
          resetVoucher();
        } else {
          setInfo(`You have entered ${formatBalance(vouchersAmount + response.data.value)}. To show your ${productName}, you need to enter another ${formatBalance(price - (vouchersAmount + response.data.value))} voucher.`);
          setVouchersAmount(amount => (amount + response.data.value));
        }
      }
      setLoading(false);
    }).catch((error) => {
      if ( error.message && error.message === 'Network Error' ) {
        setError('Network Error');
      } else {
        setError(api.handleError(error));
      }
      setLoading(false);
    });
  };

  return [
    handleSubmitVoucher,
    isVoucherPopupOpen,
    voucherPopupOpenHandler,
    voucherPopupCloseHandler,
    error,
    setError,
    info,
    loading,
    price,
    setPrice,
    vouchersAmount,
    setInfo,
    resetVoucher
  ];
};

export default useVoucherHandler;
