import React from 'react';
import { Link } from 'gatsby';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

const Layout = ({
  children,
  appCustomClass = '',
  mainCustomClass = '',
  isHomepageLinkEnabled = false,
  linkCustomClass = '',
  isMenuEnabled = true
}) => (
  <div className={`app ${appCustomClass}`}>
    <div className="app__top">
      <Header isMenuEnabled={isMenuEnabled} />
      <main className={mainCustomClass}>
        {children}
      </main>
      {
        isHomepageLinkEnabled &&
        <Link to='/' className={`back-to-main-btn ${linkCustomClass}`}>Back To Main Page</Link>
      }
    </div>
    <Footer />
  </div>
);

export default Layout;
