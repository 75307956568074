export const RESULT_PAGE_SIZE_CONFIG = [
  {
    size: 320,
    itemPerRows: 3
  },
  {
    size: 475,
    itemPerRows: 4
  },
  {
    size: 594,
    itemPerRows: 5
  },
  {
    size: 684,
    itemPerRows: 6
  },
  {
    size: 768,
    itemPerRows: 7
  },
  {
    size: 1024,
    itemPerRows: 8
  }
];
