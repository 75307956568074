import React, { useState } from 'react';

const CopyToClipboardButton = ({ textToCopy }) => {
  const [isCopy, setIsCopy] = useState(false);

  const copyToClipboardHandler = () => {
    navigator.clipboard.writeText(textToCopy).then(function() {
      setIsCopy(true);
    }, function() {
      setIsCopy(false);
      console.warn('Clipboard write failed');
    });
  };

  return (
    <div className="copy-to-clipboard-button__wrapper">
      <button type='button' onClick={copyToClipboardHandler} className='copy-to-clipboard-button'>
        Please save the links with the results before closing this page
        {
          isCopy ?
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23px"
              height="19px"
              className="copy-to-clipboard-button__ok"
            >
              <path fillRule="evenodd"
                    d="M23.011,3.793 L8.020,19.012 L-0.012,10.859 L3.736,7.054 L8.020,11.402 L19.264,-0.011 L23.011,3.793 Z"/>
            </svg>
            :
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              className="copy-to-clipboard-button__copy"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path d="M12.8 3.5V12.8H3.5V3.5H12.8ZM14 2.3H2.3V14H14V2.3ZM0 12.3V0H12.3V1.2H1.2V12.3H0Z"/>
            </svg>
        }
      </button>
    </div>
  )
};

export default CopyToClipboardButton;
