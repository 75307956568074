import React, { useState } from 'react';
import DateInput from '../../../FormElements/DateInput';

const LuckyNumbersInfo = ({ voucherPopupOpenHandler, genderLabelEnable = true, dateBegin = null, datePlaceholder = '' }) => {
  const [ageConfirm, setAgeConfirm] = useState(false);

  const handleAgeConfirmChange = event => {
    setAgeConfirm(event.target.checked);
  };

  return (
    <div className="lucky-numbers-info__wrapper">
      <h4>Generate Your Lucky Numbers</h4>
      <div className="lucky-numbers-info">
        <div className="lucky-numbers-info__gender">
          {genderLabelEnable && <p>Gender</p>}
          <div>
            <div className='lucky-numbers-info__radio'>
              <input type="radio" id="male" name="gender" value="male" defaultChecked/>
              <label htmlFor="male">Male</label>
            </div>
            <div className='lucky-numbers-info__radio'>
              <input type="radio" id="female" name="gender" value="female"/>
              <label htmlFor="female">Female</label>
            </div>
          </div>
        </div>
        <div className="lucky-numbers-info__birth">
          {!datePlaceholder && <label htmlFor="birth">Day of Birth:</label>}
          <div>
            <DateInput inputId='birth' placeholder={datePlaceholder} firstDate={dateBegin}/>
          </div>
        </div>
        <div className="lucky-numbers-info__years-old">
          <input
            type="checkbox"
            id='age-confirm'
            onChange={handleAgeConfirmChange}
            checked={ageConfirm}
          />
          <label htmlFor="age-confirm">
              <span>
                <svg width="24" height="24" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6 10.5L15 1.5L13.5 0L6 7.5L1.5 3.5L0 5L6 10.5Z" fill="black"/>
                </svg>
              </span>
            I am at least 18 years old
          </label>
        </div>
      </div>
      <button type="button" className="lucky-numbers-info__btn" onClick={voucherPopupOpenHandler}
              disabled={!ageConfirm}>Generate
      </button>
    </div>
  );
};

export default LuckyNumbersInfo;
