import React from 'react';

const PageNotFoundText = () => (
  <section className="page-not-found-section">
    <h2>404</h2>
    <h2>Page Not Found</h2>
  </section>
);

export default PageNotFoundText;
