import React, { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";
import PureDateInput from './PureDateInput';
import 'react-datepicker/dist/react-datepicker.min.css';

const DateInput = ({ inputId = 'birth', firstDate = null, placeholder = '' }) => {
  const [startDate, setStartDate] = useState(firstDate);
  const [isPortal, setIsPortal] = useState(false);
  const maxDate = new Date().setFullYear(new Date().getFullYear() - 18);

  useEffect(() => {
    const isMatchMediaQuery = size => {
      const mmq = window.matchMedia(`(min-width: ${size}px)`);
      return mmq.matches;
    };

    const resizeHandler = () => {
      if ( isMatchMediaQuery(768)) {
        setIsPortal(false);
      } else {
        setIsPortal(true);
      }
    };

    resizeHandler();
    window.addEventListener('resize', resizeHandler);

    return () => window.removeEventListener('resize', resizeHandler);
  }, []);

  return (
    <DatePicker
      id={inputId}
      selected={startDate}
      onChange={setStartDate}
      peekNextMonth
      showMonthDropdown
      showYearDropdown
      maxDate={maxDate}
      className='date-input'
      placeholderText={placeholder && placeholder}
      withPortal={isPortal}
      // onFocus={(e) => e.target.readOnly = true}
      customInput={<PureDateInput />}
    />
  );
};

export default DateInput;
