import React from 'react';
import { formatDate } from '../../../helpers/format';

const LotteryInfo = ({ lotteryLogoSrc, lotteryName, country, date }) => (
  <div className="lotteries-results__info-wrapper">
    <div className="lotteries-results__logo">
      {
        lotteryLogoSrc ?
          <img src={lotteryLogoSrc} alt={lotteryName}/>
          :
          <div><span>Lotto</span></div>
      }
    </div>
    <div className="lotteries-results__info">
      { country && <span className="lotteries-results__country">{country}</span>}
      <span className="lotteries-results__name">{lotteryName}</span>
      { date && <span className="lotteries-results__date">{formatDate(date)}</span>}
    </div>
  </div>
);

export default LotteryInfo;
