import React from "react";
import PageNotFoundText from "../../../components/PageNotFoundText";
import Layout from "../../Layout";

const NotFoundPageTemplate = ({ isMenuEnabled = false}) => (
  <Layout
    appCustomClass='app-404'
    isHomepageLinkEnabled={true}
    linkCustomClass='back-to-main-btn__secondary'
    isMenuEnabled={isMenuEnabled}
  >
    <PageNotFoundText />
  </Layout>
);

export default NotFoundPageTemplate;
