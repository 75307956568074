import lotterySrc1 from '../../images/lottery-border-1.png';
import lotterySrc2 from '../../images/lottery-border-2.png';
import lotterySrc3 from '../../images/lottery-border-3.png';
import lotterySrc4 from '../../images/lottery-border-4.png';
import lotterySrc5 from '../../images/lottery-border-5.png';
import lotterySrc6 from '../../images/lottery-border-6.png';

export default [
  {
    name: 'lotto',
    ballsCount: 6,
    maxValue: 52,
    bonusBall: 52,
    img: lotterySrc1
  },
  {
    name: 'lotto plus 1',
    ballsCount: 6,
    maxValue: 52,
    bonusBall: 52,
    img: lotterySrc2
  },
  {
    name: 'lotto plus 2',
    ballsCount: 6,
    maxValue: 52,
    bonusBall: 52,
    img: lotterySrc3
  },
  {
    name: 'daily lotto',
    ballsCount: 5,
    maxValue: 36,
    img: lotterySrc4
  },
  {
    name: 'PowerBall',
    ballsCount: 5,
    maxValue: 50,
    bonusBall: 20,
    img: lotterySrc5
  },
  {
    name: 'PowerBall plus',
    ballsCount: 5,
    maxValue: 50,
    bonusBall: 20,
    img: lotterySrc6
  }
];
