import React from 'react';
import Menu from '../../components/Menu';
import Logo from "../Logo";
import { getLogoForCurrentSite } from "../../helpers/functions";

const logoSrc = getLogoForCurrentSite();

const Header = ({ isMenuEnabled = false }) => (
  <header className="header">
    {logoSrc && <Logo src={logoSrc}/>}
    {isMenuEnabled && <Menu />}
  </header>
);

export default Header;
