import React, { useState, useEffect } from 'react';
import VoucherInput from '../../FormElements/VoucherInput';
import VoucherFormSubmitButton from '../VoucherFormSubmitButton';
import Loader from '../../Loader';
import { deleteGaps } from '../../../helpers/voucher';

const VoucherForm = ({
  onClose,
  onSubmitVoucher,
  error,
  setError,
  info,
  isSubmitOutside = true,
  iconSrc = null,
  title,
  voucherLabel,
  btnLabel = 'Show Your Lucky Numbers',
  vouchersAmount,
  price,
  infoHandler,
  redirectButtonCallback,
  loading = false
}) => {
  const [voucherNumber, setVoucherNumber] = useState('');

  useEffect(() => {
    if ( infoHandler ) {
      infoHandler();
    }
  }, []);

  useEffect(() => {
    if (error) {
      setError(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voucherNumber]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if ( vouchersAmount >= price ) {
      redirectButtonCallback();
    } else {
      onSubmitVoucher(deleteGaps(voucherNumber));
    }
    setVoucherNumber('');
  };

  const disableSubmitHandler = () => {
    if ( infoHandler && vouchersAmount >= price ) {
      infoHandler();
      return false;
    }
    return voucherNumber.length !== 19;
  };

  return (
    <>
      <div className="voucher-form__wrapper">
        <form onSubmit={handleSubmit}>
          <div className='voucher-form'>
            {iconSrc && <img src={iconSrc} alt="Voucher icon"/>}
            {title && title}
            {voucherLabel && <label htmlFor="voucher">{voucherLabel}</label>}
            <div className={"voucher-form__input-wrapper" + (info ? ' info' : '')}>
              {error && <p className='voucher-form__error-msg'>{error}</p>}
              {info && <p className='voucher-form__text-msg'>{info}</p>}
              <VoucherInput
                id="voucher"
                onChange={setVoucherNumber}
                value={voucherNumber}
                focus={true}
              />
            </div>
            {
              !isSubmitOutside &&
              <VoucherFormSubmitButton disabled={disableSubmitHandler()} btnLabel={btnLabel}/>
            }
            <div className='voucher-form__acoin-link-wrapper'>
              <a href={`${process.env.REACT_APP_ACOIN_URL}/purchase`}
                 rel="noopener noreferrer" target='_blank'
                 className='voucher-form__acoin-link'>
                <svg width="24" height="24" viewBox="0 -1 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M22 4H2C0.896 4 0 4.896 0 6V18C0 19.104 0.896 20 2 20H22C23.104 20 24 19.104 24 18V6C24 4.896 23.104 4 22 4ZM22 17.5C22 17.776 21.776 18 21.5 18H2.5C2.224 18 2 17.776 2 17.5V11H22V17.5ZM22 8H2V6.5C2 6.224 2.224 6 2.5 6H21.5C21.776 6 22 6.224 22 6.5V8ZM13 14H4V13H13V14ZM10 16H4V15H10V16ZM20 14H17V13H20V14Z"/>
                </svg>
                <span>Buy Voucher Online</span>
              </a>
            </div>
          </div>
          {
            isSubmitOutside &&
            <VoucherFormSubmitButton disabled={disableSubmitHandler()} btnLabel={btnLabel}/>
          }
        </form>
        {loading && <Loader />}
        <button className='voucher-form__close' type='button' onClick={onClose}>
          <span />
        </button>
      </div>
      <div className="voucher-form__overlay" onClick={onClose}/>
    </>
  );
};

export default VoucherForm;
