import React from 'react';

const ntd = () => {};

const CountryResultButtonsWrapper = ({
  selectedOnClickHandler = ntd,
  allOnClickHandler = ntd,
  isDisableGetSelectResult = true
}) => (
  <div className="country__btn-wrapper">
    <button
      type='button'
      className='country__btn'
      onClick={selectedOnClickHandler}
      disabled={isDisableGetSelectResult}
    >
      Get selected results
    </button>
    <button
      type='button'
      className='country__btn'
      onClick={allOnClickHandler}
    >
      Get all results
    </button>
  </div>
);

export default CountryResultButtonsWrapper;
