import React from 'react';

const TextSection = () => (
  <section className="text-section">
    <div className="container">
      <h5>Numerology Based Lucky Numbers</h5>
      <h4>Find Your Personal Lucky Numbers</h4>
      <h6>Enter the above information and the lucky number generator will calculate your lucky numbers for South Africa’s must popular lotteries.</h6>
    </div>
  </section>
);

export default TextSection;
