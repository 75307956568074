import australiaSrc from '../images/flags/australia.svg';
import austriaSrc from '../images/flags/austria.svg';
import belgiumSrc from '../images/flags/belgium.svg';
import brazilSrc from '../images/flags/brazil.svg';
import finlandSrc from '../images/flags/finland.svg';
import franceSrc from '../images/flags/france.svg';
import germanySrc from '../images/flags/germany.svg';
import greeceSrc from '../images/flags/greece.svg';
import italySrc from '../images/flags/italy.svg';
import newZealandSrc from '../images/flags/new-zealand.svg';
import polandSrc from '../images/flags/poland.svg';
import southAfricaSrc from '../images/flags/south-africa.svg';
import russiaSrc from '../images/flags/russia.svg';
import slovakiaSrc from '../images/flags/slovakia.svg';
import spainSrc from '../images/flags/spain.svg';
import swedenSrc from '../images/flags/sweden.svg';
import switzerlandSrc from '../images/flags/switzerland.svg';
import turkeySrc from '../images/flags/turkey.svg';
import usaSrc from '../images/flags/usa.svg';
import canadaSrc from '../images/flags/canada.svg';
import irelandSrc from '../images/flags/ireland.svg';
import japanSrc from '../images/flags/japan.svg';
import lithuaniaSrc from '../images/flags/lithuania.svg';
import mauritiusSrc from '../images/flags/mauritius.svg';
import singaporeSrc from '../images/flags/singapore.svg';
import unitedKingdomSrc from '../images/flags/united-kingdom.svg';
import barbadosSrc from '../images/flags/barbados.svg';
import bulgariaSrc from '../images/flags/bulgaria.svg'

const flags = {
  'au': australiaSrc,
  'at': austriaSrc,
  'be': belgiumSrc,
  'br': brazilSrc,
  'bb': barbadosSrc,
  'bg': bulgariaSrc,
  'ca': canadaSrc,
  'fi': finlandSrc,
  'fr': franceSrc,
  'de': germanySrc,
  'gr': greeceSrc,
  'ie': irelandSrc,
  'it': italySrc,
  'jp': japanSrc,
  'lt': lithuaniaSrc,
  'mu': mauritiusSrc,
  'nz': newZealandSrc,
  'pl': polandSrc,
  'za': southAfricaSrc,
  'ru': russiaSrc,
  'sg': singaporeSrc,
  'sk': slovakiaSrc,
  'es': spainSrc,
  'se': swedenSrc,
  'ch': switzerlandSrc,
  'tr': turkeySrc,
  'uk': unitedKingdomSrc,
  'us': usaSrc
};

export default flags;
