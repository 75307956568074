import React from 'react';

const LotteriesResultsHeaderDefault = ({ flagSrc, countryName }) => (
  <div className="lotteries-results__header">
    {
      flagSrc &&
      <div className='lotteries-results__header-flag' style={{
        backgroundImage: `url(${flagSrc})`
      }}>
        {/*<img src={flagSrc} alt={countryName}/>*/}
      </div>
    }
    <div>
      <h5>{countryName}</h5>
      <h5>Lottery Results</h5>
    </div>
  </div>
);

export default LotteriesResultsHeaderDefault;
