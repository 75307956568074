export const formatBalance = (credits) => {
  const creditParts = (credits / 100).toString().split('.');
  creditParts[0] = creditParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  if (creditParts.length > 1) {
    if (creditParts[1].length < 2) {
      creditParts[1] += '0';
    }
  } else {
    creditParts.push('00');
  }
  return 'R' + creditParts.join(".");
};

export const formatVoucher = (voucherCode) => {
  const voucher = deleteGaps(voucherCode);
  return voucher.replace(/(.{4})/g, '$1 ').trim();
};

export const deleteGaps = (value) => {
  return value.replace(/\s/g, '');
};
