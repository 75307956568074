import React from 'react';
import Copyright from "../Copyright";

const Footer = () => (
  <footer className="footer">
    <div className="container">
      <Copyright siteName={process.env.REACT_COPYRIGHT_TEXT && process.env.REACT_COPYRIGHT_TEXT}/>
    </div>
  </footer>
);

export default Footer;
